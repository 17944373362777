<template>
  <div>
    <label>Adicionar keyword</label>
    <div class="input-group">
      <input
        v-model="key"
        type="text"
        class="form-control"
        placeholder="Digite a keyword..."
      >
      <div class="input-group-append">
        <button
          :class="`btn btn-success ${loading ? 'm-loader' : ''}`"
          type="button"
          @click="addKeyword"
        >
          <i :class="`${loading ? '' : 'fa fa-plus'}`"></i>
        </button>

        <button
          @click="close"
          class="btn btn-danger"
          type="button"
        >
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddKeywordComponent',
  props: ['showKeys', 'keyword'],

  data () {
    return {
      key: '',
      loading: false,
    }
  },

  methods: {
    async addKeyword () {
      let validate = true;
      let keywordRepeated = false;

      if (this.key.length < 3) {
        swal("A keyword deve ter no mínimo 3 caracteres!", '', 'error');

        validate = false;
      }

      if (validate && this.keyword.slug.split('-')[0] == 'media_channel') {
        keywordRepeated = await this.validateKeywordRepeated(this.keyword.media_channel_id, this.key);
      }

      if (keywordRepeated || this.keyword.keywords.includes(this.key)) {
        swal(
          "Keyword já existe!",
          'Esta keyword já está sendo utilizada por outro serviço. É necessário criar keywords únicas para cada oferta e serviço.',
          'error'
        );

        validate = false;
      }


      if (validate) {
        this.keyword.keywords.push(this.key);
        this.key = '';
        this.$emit('showKeys', true);
      }
    },

    async validateKeywordRepeated (mediaChannelID, keyword) {
      this.loading = true;
      const response = await axios.get(`api/keyword/keywordRepeat/${mediaChannelID}`, {
        params: {
          keyword
        }
      });

      this.loading = false;

      return response.data.validation;
    },

    close () {
      this.key = '';
      this.$emit('showKeys', true);
    }
  },
}
</script>

<style>
</style>"