<template>
  <transition name="modal" v-if="showModal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Configurar Keywords</h5>
                <button
                  @click="close"
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body">
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-for="(validate, index) in validates"
                  :key="index"
                >
                  {{ validate }}
                </div>

                <form class="row pr-4 pl-4">
                  <section class="col-6 form-group">
                    <div>
                      <label for="channel" class="form-control-label"
                        >Canal:</label
                      >
                      <div v-if="loadingKeywords">Carregando...</div>
                      <select
                        v-else
                        v-validate="'required'"
                        data-vv-name="canal"
                        v-model="keyword.slug"
                        disabled
                        class="form-control"
                      >
                        <option
                          :value="channel.slug"
                          v-for="(channel, index) in channels"
                          :key="index"
                        >
                          {{ channel.name }}
                        </option>
                      </select>
                      <div class="text-danger">
                        {{ errors.collect("canal")[0] }}
                      </div>
                    </div>
                  </section>

                  <section
                    class="col-6 form-group"
                    v-if="
                      keyword.slug &&
                      keyword.slug.split('-')[0] == 'media_channel'
                    "
                  >
                    <label class="form-control-label">Tipo de Keyword:</label>
                    <div class="m-radio-inline mt-3">
                      <label
                        class="m-radio m-radio--success"
                        v-for="(category, index) in categories"
                        :key="index"
                      >
                        <input
                          v-validate="'required'"
                          data-vv-name="tipo"
                          v-model="keyword.category_keyword_id"
                          type="radio"
                          name="type"
                          :value="category.id"
                        />
                        {{ category.name }}
                        <span></span>
                      </label>
                      <div class="text-danger">
                        {{ errors.collect("tipo")[0] }}
                      </div>
                    </div>
                  </section>

                  <section
                    class="col-12 form-group"
                    v-if="keyword.channel_id || keyword.media_channel_id"
                  >
                    <div v-if="showKeys">
                      <template v-if="keyword.keywords != ''">
                        <span
                          class="mr-2 mb-2 btn btn-success m-btn btn-sm m-btn--custom m-btn--icon m-btn--pill m-btn--air mt-2"
                          v-for="(keyword, index) in keyword.keywords"
                          :key="index"
                          @click="removeKeyword(index)"
                        >
                          <div class="pt-0">
                            <span style="float: left">{{ keyword }}</span>
                            <span style="float: left" class="ml-2"
                              ><i class="fa fa-times"></i
                            ></span>
                          </div>
                        </span>
                      </template>

                      <button
                        v-if="showKeys"
                        @click="showKeys = false"
                        title="Adicionar keyword"
                        type="button"
                        class="btn btn-info m-btn btn-sm m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill"
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>

                    <div class="form-group m-form__group" v-if="!showKeys">
                      <AddKeywordComponent
                        :keyword="keyword"
                        :showKeys="showKeys"
                        @clearKey="key = $event"
                        @showKeys="showKeys = $event"
                      />
                    </div>
                  </section>
                </form>
              </div>

              <hr />

              <div class="modal-footer">
                <span class="col-5">
                  <button
                    v-if="keyword.id && service.status_register == 'updating'"
                    @click="destroy"
                    type="button"
                    :class="`btn btn-danger ${
                      loading ? 'm-loader m-loader--light m-loader--left' : ''
                    }`"
                    data-dismiss="modal"
                  >
                    <span :class="loading ? 'pl-2' : ''">
                      {{ loading ? "Deletando..." : "Excluir" }}</span
                    >
                  </button>
                </span>

                <span class="col-7 text-right">
                  <button
                    @click="close"
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Cancelar
                  </button>

                  <button
                    @click="save"
                    type="button"
                    :class="`btn btn-info ${
                      saving ? 'm-loader m-loader--light m-loader--left' : ''
                    }`"
                  >
                    <span :class="saving ? 'pl-2' : ''">
                      {{ saving ? "Salvando.." : "Salvar" }}</span
                    >
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import AddKeywordComponent from "./AddKeywordComponent.vue";
export default {
  name: "KeywordForm",

  props: {
    id: {
      type: String
    },

    service: {
      type: Object
    },

    showModal: {
      type: Boolean
    },

    keyword: {
      type: Object,
      required: true
    }
  },

  watch: {
    showModal() {
      let key = "SMS";

      if (!this.keyword.id) {
        key = null;
      }

      this.getChannels(key);
    }
  },

  components: {
    AddKeywordComponent
  },

  data() {
    return {
      channel: "",
      loading: false,
      loadingKeywords: false,
      saving: false,
      tags: [],
      categories: [],
      validates: [],
      channels: [],
      showKeys: true
    };
  },

  mounted() {
    this.getKeywordCategories();
  },

  methods: {
    validation() {
      let validation = true;

      if (!this.keyword.keywords.length) {
        validation = false;
        swal("Adicione uma keyword!", "", "error");
      }

      return validation;
    },

    save() {
      this.$validator.validateAll().then(result => {
        if (this.validation() && result) {
          this.saving = true;

          if (!this.keyword.id) {
            return this.store();
          }

          return this.update();
        }
      });
    },

    store() {
      axios
        .post("api/keyword", this.keyword)
        .then(response => {
          this.saving = false;

          toastr.success("Salvo com sucesso!", {
            positionClass: "toast-bottom-right"
          });

          this.$emit("list", false);
        })
        .catch(err => {
          console.log(err);
          this.saving = false;

          const { repository_validation } = err.response.data;
          if (repository_validation) {
            return this.validatorRepository(err.response.data);
          }

          this.validator(err.response.data.errors);

          toastr.error("Erro ao salvar!", {
            positionClass: "toast-bottom-right"
          });
        });
    },

    update() {
      axios
        .put(`api/keyword/${this.keyword.id}`, this.keyword)
        .then(response => {
          this.saving = false;

          toastr.success("Salvo com sucesso!", {
            positionClass: "toast-bottom-right"
          });

          this.$emit("list", false);
        })
        .catch(err => {
          console.log(err);
          this.saving = false;

          const { repository_validation } = err.response.data;
          if (repository_validation) {
            return this.validatorRepository(err.response.data);
          }

          this.validator(err.response.data.errors);
          toastr.error("Erro ao salvar!", {
            positionClass: "toast-bottom-right"
          });
        });
    },

    destroy() {
      swal({
        title: "Tem certeza que deseja Deletar?",
        text: "Uma vez deletado não poderá mais recuperar esse registro.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, desejo deletar"
      }).then(willDelete => {
        if (willDelete.value) {
          this.loading = true;
          axios
            .delete(`api/keyword/${this.keyword.id}`)
            .then(response => {
              this.loading = false;
              this.$emit("list", false);
              toastr.success("Deletado com sucesso.", {
                positionClass: "toast-bottom-right"
              });
            })
            .catch(err => {
              console.log(err);
              this.loading = false;
            });
        }
      });
    },

    getChannels(key) {
      this.loadingKeywords = true;

      const params = {};

      if (key) {
        params.key = key;
      }

      axios
        .get("api/keyword/channel/all", params)
        .then(response => {
          this.channels = response.data.data;

          if (!this.keyword.id) {
            this.keyword.slug = this.channels[0].slug;
            this.changeChannel(this.keyword.slug);
          }
        })
        .finally(() => {
          this.loadingKeywords = false;
        });
    },

    getKeywordCategories() {
      axios
        .get("api/keyword/category/all")
        .then(response => {
          this.categories = response.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },

    validatorRepository(data) {
      this.validates = [];

      const { message } = data;
      this.validates = message;
    },

    validator(validates) {
      this.validates = [];

      Object.keys(validates).forEach(key => {
        this.validates.push(validates[key][0]);
      });
    },

    removeKeyword(index) {
      this.keyword.keywords.splice(index, 1);
    },

    changeChannel(value) {
      const slug = value.split("-");
      const type = slug[0];
      const id = parseInt(slug[1]);

      this.keyword.keywords = [];
      this.keyword.type = type;

      if (type == "channel") {
        this.keyword.media_channel_id = null;
        this.keyword.channel_id = id;
        return;
      }

      this.keyword.channel_id = null;
      this.keyword.media_channel_id = id;
      return;
    },

    close() {
      this.validates = [];
      this.showKeys = true;
      this.$emit("close", false);
    }
  }
};
</script>

<style scopped>
.button-plus {
  width: 25px !important;
  height: 25px !important;
}

.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 100%;
  margin: 0px auto;
  padding: 20px 30px;
  border-radius: 2px;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>