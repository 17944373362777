<template>
  <main>
    <KeywordForm
      :keyword="keyword"
      :service="service"
      :showModal.sync="showModal"
      @close="showModal = $event"
      @list="list($event)"
    />

    <div id="accordion">
      <div class="card" v-for="(offer, index) in offers" :key="index">
        <div class="m-portlet__head" :id="`heading${index}`">
          <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
              <h3
                style="cursor: pointer"
                class="m-portlet__head-text"
                data-toggle="collapse"
                :data-target="`#collapse${index}`"
                aria-expanded="true"
                :aria-controls="`collapse${index}`"
              >
                <span>
                  <i class="fas fa-angle-down"></i>
                  {{ offer.name }}
                </span>
              </h3>
            </div>
          </div>
        </div>

        <div
          :id="`collapse${index}`"
          :class="`collapse ${index == 0 ? 'show' : ''}`"
          :aria-labelledby="`heading${index}`"
        >
          <div class="card-body">
            <div class="table table-responsive">
              <table width="100%">
                <thead>
                  <th>Canal</th>
                  <th>Tipo</th>
                  <th>Keyword</th>
                  <th style="text-align: right">
                    <button
                      v-if="service.status_register == 'updating' && $authorization.can('service-update')"
                      @click="add(offer.id)"
                      type="button"
                      class="btn btn-success btn-sm"
                      data-toggle="modal"
                      data-target="#m_modal_4"
                    >
                      + Adicionar Keyword
                    </button>
                  </th>
                </thead>

                <tbody v-if="offer.keywords.data.length">
                  <tr
                    v-for="(keyword, index) in offer.keywords.data"
                    :key="index"
                  >
                    <td>
                      <span
                        v-if="keyword.channel"
                        class="m-badge m-badge--info m-badge--wide"
                        >{{ keyword.channel.data.name }}</span
                      >
                      <span v-else> - </span>
                    </td>

                    <td>
                      <span
                        v-if="keyword.mediaChannel"
                        class="m-badge m-badge--info m-badge--wide"
                        >{{ keyword.mediaChannel.data.name }}</span
                      >
                      <span v-else> - </span>
                    </td>

                    <td>
                      <span
                        class="m-badge m-badge--success m-badge--wide"
                        v-if="keyword.category"
                        >{{ keyword.category.data.name }}</span
                      >

                      <span v-else> - </span>
                    </td>

                    <td>
                      <template v-if="keyword.keywords">
                        <span
                          class="m-badge m-badge--wide mr-2"
                          v-for="(keyword, index) in keyword.keywords.split(
                            ';'
                          )"
                          :key="index"
                          >{{ keyword }}</span
                        >
                      </template>
                    </td>

                    <td style="text-align: right">
                      <a
                        v-if="service.status_register == 'updating'"
                        href="javascript:void(0)"
                        @click="edit(keyword)"
                      >
                        <i class="flaticon-cogwheel-2"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>

                <tbody v-else>
                  <td colspan="12">
                    <p class="text-center mt-4">Nenhum Registro encontrado</p>
                  </td>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import KeywordForm from "./KeywordForm";

export default {
  name: "KeywordList",

  components: {
    KeywordForm
  },

  props: {
    service: {
      type: Object
    },

    offers: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      showModal: false,

      keyword: {
        id: 0,
        service_id: null,
        offer_id: null,
        channel_id: null,
        media_channel_id: null,
        category_keyword_id: null,
        keywords: [],
        tags: []
      }
    };
  },

  methods: {
    list(event) {
      this.showModal = event;
      this.$emit("getOffers");
    },

    add(offerID) {
      this.keyword = {
        id: 0,
        service_id: this.service.id,
        offer_id: offerID,
        channel_id: null,
        media_channel_id: null,
        category_keyword_id: null,
        keywords: [],
        tags: []
      };

      this.showModal = true;
    },

    edit(keyword) {
      this.keyword = { ...keyword };

      if (!this.keyword.keywords) {
        this.keyword.keywords = [];
        return (this.showModal = true);
      }

      this.keyword.keywords = this.keyword.keywords.split(";");
      return (this.showModal = true);
    }
  }
};
</script>