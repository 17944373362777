<template>
  <main>
    <header-service
      :service="service"
      currentTab="Ofertas"
      :isSaving="!isLoading && isSaving"
      @saving="save()"
    ></header-service>

    <div class="row mt-5">
      <div class="col-lg-3 col-md-4">
        <menu-service :service="service"></menu-service>
      </div>

      <div class="col-lg-9 col-md-8">
        <u-portlet title="Gestão de Keywords">
          <div class="text-center">
            <div
              v-if="isLoading"
              class="m-loader m-loader--danger"
              style="width: 30px; display: inline-block"
            ></div>
          </div>

          <KeywordList
            v-if="!isLoading"
            :offers="offers"
            @getOffers="getOffers"
            :service="service"
          />
        </u-portlet>
      </div>
    </div>
  </main>
</template>

<script>
import HeaderService from "../components/HeaderService";
import KeywordList from "../components/Keyword/KeywordList";
import MenuService from "../components/MenuService";
import ServiceService from "../services/ServiceService";
import OfferService from "../services/OfferService";
import UPortlet from "../../../components/UPortlet";

export default {
  props: ["id"],
  name: "Keywords",

  components: {
    MenuService,
    UPortlet,
    KeywordList,
    HeaderService
  },

  data() {
    return {
      isLoading: true,
      isSaving: false,
      serviceService: new ServiceService(),
      offerService: new OfferService(),
      service: {},
      offers: [],
      perfils: [1, 2]
    };
  },

  created() {
    this.getService();
    this.getOffers();
  },

  methods: {
    save() {
      this.isSaving = true;

      this.serviceService
        .save(this.service)
        .then(response => {
          this.isSaving = false;
          swal("Sucesso!", "Serviço atualizado com sucesso.", "success");

          this.service.status_register = response.data.status_register;
        })
        .catch(error => {
          this.isSaving = false;

          swal("Ops!", this.$helper.formRequestErrorsMessage(error), "error");
        });
    },

    convertDatePtBrService() {
      if (this.service.vigency_start) {
        this.service.vigency_start = moment(this.service.vigency_start).format(
          "DD/MM/YYYY"
        );
      }

      if (this.service.vigency_end) {
        this.service.vigency_end = moment(this.service.vigency_end).format(
          "DD/MM/YYYY"
        );
      }
    },

    editForm() {
      swal({
        title: "Atenção!",
        type: "warning",
        text:
          "Deseja realmente alterar o cadastro deste serviço? O status do serviço será modificado e o mesmo deverá ser enviado novamente para análise.",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        confirmButtonClass: "some-class",
        cancelButtonClass: "some-other-class",
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.updateStatusOffer();
        }
      });
    },

    updateStatusOffer() {
      axios
        .put(`service/${this.service.id}/update/status`, {
          status: this.service.status,
          status_register: "updating"
        })
        .then(response => {
          this.$router.go();
        })
        .catch(err => {
          console.log(err);
        });
    },

    getOffers() {
      this.isLoading = true;
      axios
        .get(`api/offer/service/${this.id}`, {
          params: {
            include: [
              "keywords",
              "keywords.channel",
              "keywords.mediaChannel",
              "keywords.category"
            ].join()
          }
        })
        .then(response => {
          this.offers = response.data.data;
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error.message);
        });
    },

    getService() {
      let promise = this.serviceService
        .getService(this.$route.params.id)
        .then(response => {
          this.service = response.data;
          this.convertDatePtBrService();
        })
        .catch(error => {
          console.log(error.response.data);
        });
    }
  }
};
</script>
